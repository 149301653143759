.clients {

	&__heading {
		line-height: 1.09;
	}

	&__item {
		text-align: center;
	}
}
