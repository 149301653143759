.date {
	font-size: 17px;
	color: $color-dark-4;
	font-family: $font-bebas-neue;
	text-transform: uppercase;
	font-weight: 300;
	text-align: center;

	@include susy-breakpoint($md){
		text-align: left;
	}
}
