@font-face {
	font-family: 'Bebas Neue Book';
	src: url('../fonts/BebasNeueBook.eot');
	src: url('../fonts/BebasNeueBook.eot?#iefix') format('embedded-opentype'),
		url('../fonts/BebasNeueBook.woff2') format('woff2'),
		url('../fonts/BebasNeueBook.woff') format('woff'),
		url('../fonts/BebasNeueBook.ttf') format('truetype'),
		url('../fonts/BebasNeueBook.svg#BebasNeueBook') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../fonts/BebasNeueLight.eot');
	src: url('../fonts/BebasNeueLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/BebasNeueLight.woff2') format('woff2'),
		url('../fonts/BebasNeueLight.woff') format('woff'),
		url('../fonts/BebasNeueLight.ttf') format('truetype'),
		url('../fonts/BebasNeueLight.svg#BebasNeueLight') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../fonts/BebasNeue-Thin.eot');
	src: url('../fonts/BebasNeue-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/BebasNeue-Thin.woff2') format('woff2'),
		url('../fonts/BebasNeue-Thin.woff') format('woff'),
		url('../fonts/BebasNeue-Thin.ttf') format('truetype'),
		url('../fonts/BebasNeue-Thin.svg#BebasNeue-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../fonts/BebasNeueBold.eot');
	src: url('../fonts/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/BebasNeueBold.woff2') format('woff2'),
		url('../fonts/BebasNeueBold.woff') format('woff'),
		url('../fonts/BebasNeueBold.ttf') format('truetype'),
		url('../fonts/BebasNeueBold.svg#BebasNeueBold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../fonts/BebasNeueRegular.eot');
	src: url('../fonts/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/BebasNeueRegular.woff2') format('woff2'),
		url('../fonts/BebasNeueRegular.woff') format('woff'),
		url('../fonts/BebasNeueRegular.ttf') format('truetype'),
		url('../fonts/BebasNeueRegular.svg#BebasNeueRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../fonts/RobotoCondensed-Light.eot');
	src: url('../fonts/RobotoCondensed-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RobotoCondensed-Light.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-Light.woff') format('woff'),
		url('../fonts/RobotoCondensed-Light.ttf') format('truetype'),
		url('../fonts/RobotoCondensed-Light.svg#RobotoCondensed-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../fonts/RobotoCondensed-LightItalic.eot');
	src: url('../fonts/RobotoCondensed-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RobotoCondensed-LightItalic.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-LightItalic.woff') format('woff'),
		url('../fonts/RobotoCondensed-LightItalic.ttf') format('truetype'),
		url('../fonts/RobotoCondensed-LightItalic.svg#RobotoCondensed-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../fonts/RobotoCondensed-Bold.eot');
	src: url('../fonts/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RobotoCondensed-Bold.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-Bold.woff') format('woff'),
		url('../fonts/RobotoCondensed-Bold.ttf') format('truetype'),
		url('../fonts/RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../fonts/RobotoCondensed-Regular.eot');
	src: url('../fonts/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RobotoCondensed-Regular.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-Regular.woff') format('woff'),
		url('../fonts/RobotoCondensed-Regular.ttf') format('truetype'),
		url('../fonts/RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../fonts/RobotoCondensed-Italic.eot');
	src: url('../fonts/RobotoCondensed-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RobotoCondensed-Italic.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-Italic.woff') format('woff'),
		url('../fonts/RobotoCondensed-Italic.ttf') format('truetype'),
		url('../fonts/RobotoCondensed-Italic.svg#RobotoCondensed-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../fonts/RobotoCondensed-BoldItalic.eot');
	src: url('../fonts/RobotoCondensed-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/RobotoCondensed-BoldItalic.woff2') format('woff2'),
		url('../fonts/RobotoCondensed-BoldItalic.woff') format('woff'),
		url('../fonts/RobotoCondensed-BoldItalic.ttf') format('truetype'),
		url('../fonts/RobotoCondensed-BoldItalic.svg#RobotoCondensed-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
}
