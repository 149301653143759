.icon {

	&--card {
		shape-rendering: geometricPrecision;
		text-rendering: geometricPrecision;
		image-rendering: optimizeQuality;
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: $color-dark-2;

		.fil0 {
			fill: $color-dark-2;
		}

		.fil1 {
			fill: $color-dark-2;
		}
	}

	&--menu {
		shape-rendering: geometricPrecision;
		text-rendering: geometricPrecision;
		image-rendering: optimizeQuality;
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: white;

		.fil0 {
			fill: $color-gray;
		}
	}

	&--menu-on {
		// fill: $color-white;
	}

	&--logo {
		shape-rendering: geometricPrecision;
		text-rendering: geometricPrecision;
		image-rendering: optimizeQuality;
		fill-rule: evenodd;
		clip-rule: evenodd;
		width: 100%;

		.fil2 {
			fill: $color-red;
		}

		.fil0 {
			fill: $color-dark;
			fill-rule:nonzero;
		}

		.fil1 {
			fill: $color-red;
			fill-rule:nonzero;
		}
	}

	&--contacts {
		shape-rendering: geometricPrecision;
		text-rendering: geometricPrecision;
		image-rendering: optimizeQuality;
		fill-rule: evenodd;
		clip-rule: evenodd;
	}

	&--point {
		stroke: $color-white;
		fill:none;
		stroke-width:6.84551;

		.str1 {
				stroke: $color-white;
				stroke-width:6.84551;
			}

		.str0 {
			stroke: $color-white;
			stroke-width: 6.84551;
			stroke-linejoin: round;
		}

		.fil0 {
			fill:none;
		}
	}

	&--map {
		fill:none;
		stroke: $color-white;
		stroke-width: 17.8036;

		.str0 {
			stroke: $color-white;
			stroke-width: 17.8036;
		}

		.fil0 {
			fill:none;
		}
	}
}
