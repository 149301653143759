.billboard {

	&__container {
		@include vendor-prefix(justify-content, space-between);
		padding-top: 75px;
		padding-bottom: 70px;
	}

	&__sitename {

		@include susy-breakpoint($lg){
			@include vendor-prefix(align-self, flex-end);
			@include vendor-prefix(flex, 0 0 470px);
			@include vendor-prefix(align-self, flex-start);
			text-align: left;
			letter-spacing: -.018em;
		}
	}

	&__desc {

		@include susy-breakpoint($lg){
			@include vendor-prefix(align-self, flex-end);
			@include vendor-prefix(flex, 0 0 350px);
		}
	}

	&__button {
		letter-spacing: 0.05em;
	}

	&__button-wrap {
		@include susy-breakpoint($lg){
			text-align: left;
		}
	}
}
