.news {

	&__img {
		padding-top: 25px;
		padding-bottom: 16px;
	}

	&__date {
		padding-bottom: 8px;
	}

	&__name {
		padding-bottom: 8px;
		display: block;
	}

	&__link {
		color: inherit;
		text-decoration: none;
	}

	&__desc {
		padding-bottom: 26px;
	}

	&__buttons {
		padding-bottom: 15px;
	}
}
