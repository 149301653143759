.link {
	display: inline-block;
	-webkit-transition: all $animation-speed;
	-moz-transition: all $animation-speed;
	-ms-transition: all $animation-speed;
	-o-transition: all $animation-speed;
	transition: all $animation-speed;

	&:hover {
		color: $color-brand;
		text-decoration: none;
	}

	&--img {
		font-size: 0;
		line-height: 0;
		width: 100%;
		height: 100%;
		text-decoration: none;
		outline: none;
	}

	&--tel {
		color: inherit;
		text-decoration: none;
		cursor: default;

		&:hover {
			color: inherit;
		}
	}

	&--mail {
		color: inherit;
		text-decoration: none;
	}
}
