.sandwich {
	display: none;

	&--active {
		cursor: pointer;
		padding-top: 20px;
		display: block;

		@include susy-breakpoint(300px){
			display: inline-block;
			position: relative;
			// top: 13px;
			margin-left: 30px;
			padding-top: 0;
		}

		@include susy-breakpoint(300px){
			// top: 18px;
		}


		.icon {
			-webkit-transition: all $animation-speed;
			-moz-transition: all $animation-speed;
			-ms-transition: all $animation-speed;
			-o-transition: all $animation-speed;
			transition: all $animation-speed;

			&:hover {
				fill: $color-brand;
			}
		}
	}

	&__icon {
		max-width: 50px;
		max-height: 50px;
		fill: $color-base;

		@include susy-breakpoint(300px){
			max-width: 40px;
			max-height: 40px;
		}

		@include susy-breakpoint(300px){
			max-width: 50px;
			max-height: 50px;
		}
	}
}
