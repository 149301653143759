.section {
	@include clearfix;
	align-content: space-between;

	&--light {
		background: $section-bg-light;
	}

	&--dark {
		background: $section-bg-dark;
	}

	&--brand {
		background: $section-bg-brand;
	}

	&--billboard {
		background: url('../img/bg-billboard.jpg') center no-repeat;
		background-size: cover;
	}

	&--texture-1 {
		background: url('../img/bg-texture-1.jpg') no-repeat center center;
		background-size: cover;
	}

	&--footer {
		border-top: 1px solid $color-base;
	}
}
