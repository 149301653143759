.o-media {
    display: flex;

    &__image {
        flex: 0 0 auto;
    }
}

.o-media--sm {

    @media (max-width: $sm) {
        flex-direction: column-reverse;
        text-align: center;
    }

    .o-media__image {
        @media (max-width: $sm) {
            margin-right: 0 !important;
            padding-bottom: 20px;
        }
    }
}