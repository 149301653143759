.u-hover-parent {

	&:hover {
		.u-hover-show {
			opacity: 1;
			max-height: 600px;
		}

		.u-hover-scale {
			@include susy-breakpoint($md){
				@include vendor-prefix(transform, scale(1.3));
				border: 2px solid $color-brand;
				z-index: 4;
			}
		}
	}
}

.u-hover-show {

	@include susy-breakpoint($md){
		position: absolute;
		overflow: hidden;
		opacity: 0;
		max-height: 0;
		width: calc(100% + 30px);
		margin-left: -15px;
		padding-left: 15px;
		margin-top: -5px;
		border-radius: 0 0 10px 10px;
		@include vendor-prefix(transition, max-height $animation-speed);
		z-index: 2;
	}
}

.u-hover-scale {
	display: none;

	@include susy-breakpoint($lg){
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include vendor-prefix(transition, transform $animation-speed);
		z-index: 2;
	}
}

