.alert {
	padding: 10px;
	line-height: 1.6;
	font-weight: normal;
	border-radius: 5px;
	width: 100%;
	margin: 0;

	@include susy-breakpoint(600px){
		padding: 10px 17px;
	}

	span {
		display: block;
	}

	&--error {
		background-color: $alert-danger-bg;
		color: $alert-danger-color;
	}

	&--success {
		background-color: $alert-success-bg;
		color: $alert-success-color;
	}
}
