@at-root {
  @-ms-viewport { width: device-width; }
}

html {
	box-sizing: border-box;
	color: $color-base;
	font-family: $font-family-base;
	font-size: $font-size-base-2;
	line-height: $line-height-base;
	font-weight: normal;
	font-style: normal;
  	-ms-overflow-style: scrollbar;
	height: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
	-webkit-display: flex;
	-moz-display: flex;
	-ms-display: flex;
	-o-display: flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	-o-justify-content: space-between;
	justify-content: space-between;
	height: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   /* IE10+ specific styles go here */
   body {
	   display: block;
   }
}

a {
	color: $color-red;
}

/* .slick-list {
	.slick-loading & {
		background: #fff url("../img/ajax-loader.gif") center center no-repeat;
	}
} */
