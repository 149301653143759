.contacts {

	&__phone {
		letter-spacing: -.007em;
		line-height: 1;
	}

	&--header {
		text-align: center;

		@include susy-breakpoint($lg){
			@include vendor-prefix(display, flex);
			@include vendor-prefix(align-items, center);
			@include vendor-prefix(justify-content, flex-end);
			padding-top: 10px;
		}

		.contacts {

			&__phone {
				padding-top: 5px;
				padding-bottom: 5px;

				@include susy-breakpoint($xs){
					padding-bottom: 10px;
				}

				@include susy-breakpoint($md){
					margin-right: 27px;
					padding-bottom: 0;
				}
			}

			&__button-wrap {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
			}
		}
	}

	&--bottom {
		.contacts {
			&__phone {
				padding-top: 20px;
				padding-bottom: 20px;
				margin-right: 0;
				text-align: center;
				letter-spacing: 0.01em;
				line-height: 1.23;

				@include susy-breakpoint($md){
					padding-top: 3px;
					padding-bottom: 0;
				}
			}

			&__button {
				padding: 12px 21px 6px;
				letter-spacing: 0.018em;
			}
		}
	}

	&__card {
		height: 100%;
		@include vendor-prefix(display, flex);
		@include vendor-prefix(align-items, center);
		@include vendor-prefix(justify-content, center);

		&--pull-right {
			@include susy-breakpoint($md){
				@include vendor-prefix(justify-content, flex-end);
			}
		}
	}

	&__img {
		padding-right: 10px;

		@include susy-breakpoint($md){
			padding-right: 21px;
    		padding-top: 12px;
		}
	}

	&__icon {

		&--point {
			width: 20px;
			height: 36px;

			@include susy-breakpoint($md){
				width: 40px;
				height: 56px;
			}
		}

		&--map {
			width: 23px;
			height: 17px;

			@include susy-breakpoint($md){
				width: 52px;
				height: 35px;
			}
		}
	}

	&__desc {
		text-align: left;
	}
}
