.list {
	padding-left: 27px;

	@include susy-breakpoint($md){
		list-style: none;
		margin-bottom: 23px;
	}

	&--md {
		@include fontsize(29px, 1.5, 1.333);

		.list__item {
			letter-spacing: -.016em;
			line-height: 1.37;

			&::before {
				top: 11px;
				width: 7px;
				height: 7px;

				@include susy-breakpoint($lg){
					top: 19px;
				}
			}
		}
	}

	&--pull-right {

		@include susy-breakpoint($md){
			padding-left: 0;
			padding-right: 27px;
			text-align: right;

			.list__item {
				&::before {
					left: auto;
					right: -15px;
				}
			}
		}
	}

	&__item {
		position: relative;

		@include susy-breakpoint($md){

			&::before {
				@include pseudo;
				width: 4px;
				height: 4px;
				background: $color-base;
				left: -15px;
				top: 9px;
				border-radius: 50%;
			}
		}
	}
}
