.footer {
	text-align: center;
	color: $color-brand;

	@include susy-breakpoint($sm){
		text-align: right;
	}

	&__copy {
		letter-spacing: -0.01em;
	}
}
