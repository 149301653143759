.card {
	position: relative;

	&__img {
		display: block;
	}

	&__link {
		width: 100%;

		&:hover {

			.service__icon {
				fill: $color-brand;
				.fil0,
				.fil1 {
					fill: $color-brand;
				}
			}

			.service__name {
				color: $color-brand;
			}
		}
	}

	&__name {
		display: inline-block;
	}
}
