.topmenu {

	&--desktop {

		.menu {

			&__list {

				&--level-1 {
					display: none;

					@include susy-breakpoint($lg){
						display: flex;
					}

					.menu__link {
						color: $color-white;

						@include susy-breakpoint($lg){
							font-size: 20px;
							padding: 30px 38px;
						}

						@include susy-breakpoint(1200px){
							padding: 30px 31px;
							font-size: 27px;
						}
					}
				}

				&--level-2,
				&--level-3 {
					background: $color-white;
					width: 100%;

					.menu__item {

						&:hover,
						&--active {

							& > .menu__link {
								color: $color-white;
							}
						}
					}

					.menu__link {
						color: $color-base;

						@include susy-breakpoint($lg){
							font-size: 16px;
							padding: 10px 15px;
						}

						@include susy-breakpoint(1200px){
							padding: 10px 15px;
							font-size: 20px;
						}
					}
				}

				&--level-2 {
					border-top: 1px solid $color-dark;
				}

				&--level-3 {

				}

			}

			&__item {
				flex-grow: 1;

				&--active,
				&:hover {
					& > .menu__link {
						background: $color-brand;
					}
				}
			}

			&__link {
				text-transform: uppercase;
				font-family: $font-family-heading;
				font-weight: normal;
				text-decoration: none;
				// color: $color-white;
			}

			&__icon {
				position: relative;

				@include susy-breakpoint($lg){
					// top: 5px;
					margin-right: 8px;
					max-width: 30px;
					max-height: 25px;
				}

				@include susy-breakpoint(1200px){
					// top: 10px;
					max-width: 46px;
					max-height: 39px;
				}
			}
		}
	}

	&--phone {
		overflow: auto;

		.menu {
			position: fixed;
			width: 100%;
			z-index: 101;
			top: 0;
			left: 0;
			text-align: center;
			overflow: auto;
			padding: 0 50px 0 15px;

			@include susy-breakpoint(270px){
				padding: 0 50px 0 65px;
			}

			&__list {
				max-width: 400px;
				min-width: 200px;
				display: inline-block;
				text-align: left;

				&--level-2 {

					& > .menu__item {
						text-transform: none;
						font-weight: 400;
						position: relative;
					}

					.menu__link {
						font-size: 18px;
					}
				}

				&--level-1 {
					padding-top: 30px;
					padding-bottom: 30px;
				}

				&--level-2 {
					padding: 10px 0px 10px;

					.icon {
						margin-right: 13px;
					}
				}
			}

			&__item {
				text-transform: uppercase;
				font-weight: 700;
				margin-bottom: 4px;

				&--active {

					& > span.menu__link {
						text-decoration: underline;
						color: $color-brand;

						.icon {
							fill: $color-brand;
						}
					}
				}
			}

			&__link {
				color: white;
				text-decoration: none;
				position: relative;

				.icon {
					max-width: 40px;
					max-height: 34px;
					position: relative;
					margin-bottom: 15px;
					margin-right: 9px;
					// margin-right: auto;
					// margin-left: auto;
					display: block;

					@include susy-breakpoint(270px){
						display: inline-block;
						margin-bottom: 0;
						margin-left: -50px;
						// top: 10px;
					}
				}

				&:hover {
					text-decoration: underline;
					color: $color-brand;

					.icon {
						.fil0,
						.fil1 {
							fill: $color-brand;
						}
					}
				}
			}
		}
	}
}
