// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

// Псевдоэлементы
@mixin pseudo($display: block, $pos: absolute, $content: ''){
	content: $content;
	display: $display;
	position: $pos;
}

// Треугольники
// @include triangle(red,down,10px,absolute,true);

@mixin triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
	@include pseudo($pos: $position);
	width: 0;
	height: 0;
	@if $round {
		border-radius: 3px;
	}
	@if $direction == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		margin-top: 0 - round( $size / 2.5 );
	} @else if $direction == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		margin-bottom: 0 - round( $size / 2.5 );
	} @else if $direction == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		margin-right: -$size;
	} @else if  $direction == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		margin-left: -$size;
	}
}

// Префиксы
// @include prefix(transform, rotate(90deg), webkit ms);
@mixin prefix($property, $value, $prefixes: ()) {
	@each $prefix in $prefixes {
		-#{$prefix}-#{$property}: $value;
	}

	#{$property}: $value;
}

@mixin vendor-prefix($name, $argument) {
	-webkit-#{$name}: $argument;
	-ms-#{$name}: $argument;
	-moz-#{$name}: $argument;
	-o-#{$name}: $argument;
	#{$name}: $argument;
}

@mixin fontsize($size, $start, $middle, $large: $middle){
	font-size: $size / $start;

	@include susy-breakpoint($xs){
		font-size: $size / $middle;
	}

	@include susy-breakpoint($md){
		font-size: $size / $large;
	}

	@include susy-breakpoint($lg){
		font-size: $size;
	}
}

@mixin clearfix {
	&:before,
	&:after	{
		content: '';
		display: table;
		clear: both;
	}
}
