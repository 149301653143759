 $i: 0;
 @while $i < 155 {
 	.u-mt-#{$i} {
 		margin-top: #{$i / 2}px;

 		@include susy-breakpoint($md){
 			margin-top: #{$i}px;
 		}
 	}
 	$i: $i + 5;
 }

 $k: 0;
 @while $k < 155 {
 	.u-mb-#{$k} {
 		margin-bottom: #{$k / 2}px;

 		@include susy-breakpoint($md){
 			margin-bottom: #{$k}px;
 		}
 	}
 	$k: $k + 5;
 }


 $k: 0;
 @while $k < 155 {
 	.u-mr-#{$k} {
 		margin-right: #{$k / 2}px;

 		@include susy-breakpoint($md){
 			margin-right: #{$k}px;
 		}
 	}
 	$k: $k + 5;
 }

 $k: 0;
 @while $k < 155 {
 	.u-ml-#{$k} {
 		margin-left: #{$k / 2}px;

 		@include susy-breakpoint($md){
 			margin-left: #{$k}px;
 		}
 	}
 	$k: $k + 5;
 }