.heading {
	font-family: $font-family-heading;
	font-weight: bold;
	text-decoration: none;
	color: $color-base;
	text-align: center;
	line-height: 1;

	&--light {
		font-family: $font-bebas-neue-book;
		font-weight: normal;
	}

	&--pull-left {
		@include susy-breakpoint($md){
			text-align: left;
		}
	}

	&--normal {
		font-weight: normal;
	}

	&--inverse {
		color: $color-white;
	}

	&--gray {
		color: $color-gray-2;
	}

	&--brand {
		color: $color-red;
	}

	.subheading {
		font-family: $font-family-base;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 0.37em;
		line-height: 1;
		margin-top: -5px;
		letter-spacing: 0.04em;
	}

	&--xs,
	&--sm,
	&--md {
		font-family: $font-family-base;
		font-weight: 300;
	}

	&--xs {
		@include fontsize(21px, 1, 1);
		line-height: 1.2;
	}

	&--sm {
		@include fontsize(31px, 1.2, 1.2);
		line-height: 1.064;
	}

	&--md {
		@include fontsize(40px, 1.5, 1.333);
		line-height: 1.03;
	}

	&--lg {
		@include fontsize(62px, 2, 1.333);
	}

	&--xl {
		@include fontsize(83px, 2.2, 1.333);
		// letter-spacing: -.018em;
		line-height: 1.135;
	}

	&--xxl {
		@include fontsize(125px, 2.5, 2);
		line-height: .9;
	}
}
