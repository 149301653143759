.close {
	display: none;

	&--active {
		display: block;
		position: fixed;
		top: 15px;
		right: 15px;
		width: 40px;
		height: 40px;
		z-index: 105;
		cursor: pointer;
	}

	&__icon {
		width: 40px;
		height: 40px;
		fill: $color-white;

		&:hover {
			fill: $color-brand;
		}
	}
}
