.mouse {
	display: none;

	&--active {
		display: block;
		position: absolute;
		bottom: 30px;
		right: 50%;
		left: 50%;
		margin: auto;
		width :34px;
		height: 55px;
	}

	&__body {
		width: 3px;
		height: 50px;
		padding: 10px 15px;
		border: 2px solid $color-white;
		border-radius: 25px;
		opacity: 0.75;
	}

	&__scroller {
		width: 3px;
		height: 10px;
		border-radius: 25%;
		background-color: $color-white;
		@include vendor-prefix(animation, scroll 2.2s cubic-bezier(.15,.41,.69,.94) infinite);
	}
}

@-webkit-keyframes scroll {
	0% { opacity: 0; }
	10% { transform: translateY(0); opacity: 1; }
	100% { transform: translateY(15px); opacity: 0;}
}
@-moz-keyframes scroll {
	0% { opacity: 0; }
	10% { transform: translateY(0); opacity: 1; }
	100% { transform: translateY(15px); opacity: 0;}
}
@-ms-keyframes scroll {
	0% { opacity: 0; }
	10% { transform: translateY(0); opacity: 1; }
	100% { transform: translateY(15px); opacity: 0;}
}
@-o-keyframes scroll {
	0% { opacity: 0; }
	10% { transform: translateY(0); opacity: 1; }
	100% { transform: translateY(15px); opacity: 0;}
}
@keyframes scroll {
	0% { opacity: 0; }
	10% { transform: translateY(0); opacity: 1; }
	100% { transform: translateY(15px); opacity: 0;}
}