.header-menu {
	display: flex;
	justify-content: center;

	@media screen and (min-width: $lg) {
		margin-top: 10px;
	}

	&__item {
		text-decoration: none;
		padding: 10px;
		text-transform: uppercase;

		@media screen and (min-width: $md) {
			font-size: larger;
		}
	}
}