.about {

	&__heading {
		line-height: 1.2;
	}

	&__desc {
		@include susy-breakpoint($xl){
			padding: 0 105px;
			line-height: 1.1;
		}
	}
}