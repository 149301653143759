.gallery {

	&__item {
		padding: 0 6px 9px;
		position: relative;
	}

	&__link {
		position: relative;
		width: 100%;

		&--shadow {
			@include susy-breakpoint($lg){
				@include vendor-prefix(filter, brightness(40%));

				&:hover {
					@include vendor-prefix(filter, brightness(100%));
					z-index: 3;
				}
			}
		}
	}

	&__image {
		z-index: 1;
	}
}
