.description {
	font-weight: 300;
	text-align: center;
	line-height: 1;

	&--align-left {
		@include susy-breakpoint($md){
			text-align: left;
		}
	}

	&--inverse {
		color: $color-white;

		.link {
			&:hover {
				color: $color-white;
				text-decoration: underline;
			}
		}
	}

	&--gray {
		color: $color-gray;
	}

	&--dark {
		color: $color-dark-3;
	}

	&--xs {
		@include fontsize(15px, 1, 1);
	}

	&--sm {
		@include fontsize(17px, 1, 1);
		line-height: 1.294;
	}

	&--md {
		@include fontsize(29px, 1.5, 1.333);
		line-height: 1.24;
		letter-spacing: 0.04em;
	}

	&--lg {
		@include fontsize(31px, 1.5, 1.333);
		letter-spacing: 0.01em;
	}
}
