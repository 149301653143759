.bootmenu {

	&__heading {
		text-align: left;
	}

	.menu {
		// margin-left: calc(50% - 70px );
		max-width: 300px;
		min-width: 200px;
		margin: 0 auto;

		@include susy-breakpoint($sm){
			max-width: none;
			min-width: none;
			margin: 0;
		}

		&__item {
			&--upper {
				text-transform: uppercase;
			}
		}

		&__link {
			color: $color-white;
			line-height: 1.49;
			text-decoration: none;
			width: auto;

			&:hover {
				color: $color-brand;
				text-decoration: underline;
			}
		}
	}
}
