.button {
		display: inline-block;
		border: none;
		background: none;
		padding: 0;
		margin: 0;
		text-decoration: none;
		font-family: $font-family-heading;
		color: $btn-base-color;
		text-transform: uppercase;
		border: 2px solid $btn-base-border-color;
		border-radius: 25px 0 25px 25px;
		padding: 6px 14px;
		-webkit-transition: all $animation-speed;
		-moz-transition: all $animation-speed;
		-ms-transition: all $animation-speed;
		-o-transition: all $animation-speed;
		transition: all $animation-speed;
		outline: none;

		&--full {
			width: 100%;
			text-align: center;
		}

		&:hover {
			background: $btn-base-bg-hover;
	 		color: $btn-base-color-hover;
		}

		&:disabled,
		&--disabled {
			color: $btn-base-color-disabled;
			border-color: $btn-base-color-disabled;
			cursor: default;
			font-weight: 300;

			&:hover {
				color: $btn-base-color-disabled;
				background: none;
			}
		}

		&--inverse {
			color: $btn-inverse-color;
			border-color: $btn-inverse-border-color;

			&:hover {
				background: $btn-inverse-bg-hover;
				color: $btn-inverse-color-hover;
			}

			&:disabled,
			&--disabled {
				cursor: default;
				color: $btn-inverse-color;
				border-color: $btn-inverse-border-color;
				font-weight: 300;

				&:hover {
					background: none;
					color: $btn-inverse-color;
				}
			}
		}

		&--corner-left {
			border-radius: 0 25px 25px 25px;
		}

		&--sm {
			border-radius: 15px 0 15px 15px;
			font-size: 18px;
			letter-spacing: 0.04em;

			&.button--corner-left {
				border-radius: 0 15px 15px 15px;
			}
		}

		&--md {
			font-size: 20px;
			padding: 10px;
			letter-spacing: 0.03em;
			border-radius: 20px 0 20px 20px;

			&.button--corner-left {
				border-radius: 0 20px 20px 20px;
			}

			@include susy-breakpoint($xl){
				min-width: 200px;
				font-size: 25px;
				padding: 6px 19px;
			}
		}

		&--lg {
			font-size: 24px;
			padding: 10px;

			@include susy-breakpoint($xl){
				font-size: 33px;
				padding: 9px 25px;
				letter-spacing: 0.03em;
			}
		}

		&--inline {
			color: $btn-base-color-disabled;
			border-color: $btn-base-color-disabled;
			display: block;
			margin-bottom: 10px;
			min-width: auto;

			@include susy-breakpoint($lg){
				margin-bottom: 0;
				display: inline-block;
				margin-left: 13px;
				margin-right: 13px;
			}

			&:hover {
				border-color: $btn-base-border-color;
			}
		}

		&--inline-active {
			color: $btn-base-color;
			border-color: $btn-base-border-color;
			font-weight: normal;
		}
}
