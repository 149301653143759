.textarea {
	background: none;
	border: none;
	padding: 10px 18px;
	margin: 0 0 12px 0;
	width: 100%;
	color: $color-gray-3;
	@include fontsize(29px, 1.5, 1.333);
	border: 2px solid $color-gray-3;
	border-radius: 5px;
	min-height: 227px;
}
