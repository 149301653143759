.user-content {
	font-size: 1rem;

	ul { 
		list-style-type: disc;
	}
}

.user-content--inverse {
	color: $color-white;
}

.user-content--align-right {

	@include susy-breakpoint($md){
		text-align: right;

		ul {
			direction: rtl;
		}
	}
}

.user-content--large {
	font-size: 1.25rem;

	@include susy-breakpoint($md){
		font-size: 1.75rem;
		line-height: 1.1;
	}
	
}