.glide  {

    &__arrow {
        width: 32px;
        height: 60px;
        top: 40%;
        padding: 0;
        border: none;
        background-color: inherit;
        box-shadow: none;

        &--left {
            left: -5px;
            background-image: url("../img/slider/prev.png");
            background-repeat: no-repeat;

            &:hover {
                background-image: url("../img/slider/prev-hover.png");
            }

            @include susy-breakpoint($md) {
                left: -45px;
            }
        }

        &--right {
            right: -5px;
            background-image: url("../img/slider/next.png");
            background-repeat: no-repeat;

            &:hover {
                background-image: url("../img/slider/next-hover.png");
            }

            @include susy-breakpoint($md) {
                right: -45px;
            }            
        }

    }
}