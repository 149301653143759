.label {
	@include fontsize(29px, 1.5, 1.333);
	margin-left: 32px;
	font-weight: 300;
	letter-spacing: 0.02em;

	&--full {
		display: block;
		margin-bottom: 10px;

		@include susy-breakpoint($md){
			margin-bottom: 23px;
		}
	}

	&--brand {
		color: $color-brand;

		.label__check-view {
			border-color: $color-brand;
		}

		.input--check:checked + .label__check-view {

			&::before {
				border-color: $color-brand;
			}
		}
	}

	&--gray {

		color: $color-gray-3;

		.label__check-view {
			border-color:  $color-gray-3;
		}

		.input--check:checked + .label__check-view {

			&::before {
				border-color:  $color-gray-3;
			}
		}
	}
}
