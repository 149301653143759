.logo {
	text-align: center;

	@include susy-breakpoint($lg){
		text-align: left;
	}

	&__link {
		max-width: 215px;
	}

	&__icon {
		max-height: 70px;
	}
}
