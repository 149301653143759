.menu {

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;

		&--horizontal {
			@include vendor-prefix(display, flex);
			@include vendor-prefix(justify-content, space-between);
		}

		&--dropdown {

			.menu__list--level-2,
			.menu__list--level-3 {
				position: absolute;
				overflow: hidden;
				opacity: 0;
				max-height: 0;
				-webkit-transition:max-height $animation-speed;
				-moz-transition:max-height $animation-speed;
				-ms-transition:max-height $animation-speed;
				-o-transition:max-height $animation-speed;
				transition:max-height $animation-speed;
				z-index: 2;
			}

			.menu__item--parent {
				position: relative;

				&:hover {

					& > .menu__list {
						opacity: 1;
						max-height: 800px;
					}
				}
			}
		}
	}

	&__link {
		// width: 100%;
		@include vendor-prefix(display, flex);
		@include vendor-prefix(align-items, center);
		-webkit-transition: all $animation-speed;
		-moz-transition: all $animation-speed;
		-ms-transition: all $animation-speed;
		-o-transition: all $animation-speed;
		transition: all $animation-speed;
	}
}
