.advantages {

	&__heading {
		letter-spacing: 0.05em;
	}

	&__desc {
		@include susy-breakpoint($md){
			padding: 0 30px;
			line-height: 1.12;
		}
	}
}