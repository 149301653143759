$susy: (
	flow: ltr,
	math: fluid,
	output: float,
	gutter-position: inside,
	container: auto,
	container-position: center,
	columns: 12,
	gutters: 0.441,
	column-width: false,
	global-box-sizing: content-box,
	last-flow: to
);

// Breakpoints
$xs: 				480px;
$sm: 				576px;
$md: 				768px;
$lg: 				992px;
$xl: 				1140px;
$desktop:			$lg;

// Colors
$color-black: 		#000;
$color-white: 		#fff;

$color-red: 			#c62e2b;

$color-dark: 		#1a1a1a;
$color-dark-2: 		#333333;
$color-dark-3: 		#666666;
$color-dark-4: 		#999999;

$color-gray: 		#e6e6e6;
$color-gray-2: 		#cccccc;
$color-gray-3: 		#808080;
$color-gray-4: 		#f0f0f0;

$color-black-a:		rgba(0,0,0,0.9);
$color-black-a-2:	rgba(0,0,0,0.8);
$color-black-a-3:	rgba(0,0,0,0.7);

// Sections
// light
$section-bg-light: 		$color-gray-4;
// dark
$section-bg-dark: 		$color-dark;
// brand
$section-bg-brand: 		$color-red;

// Buttons
// base
$btn-base-font-size: 			13px;
$btn-base-color: 				$color-red;
$btn-base-border-color:			$color-red;
$btn-base-bg-hover:			$color-red;
$btn-base-color-hover:			$color-white;
$btn-base-color-disabled: 		$color-gray-3;
// button--inverse
$btn-inverse-color: 				$color-white;
$btn-inverse-border-color:		$color-white;
$btn-inverse-bg-hover: 			$color-white;
$btn-inverse-color-hover:		$color-red;

// Font
$font-roboto-condensed:	'Roboto Condensed', 'Helvetica', sans-serif;
$font-bebas-neue-book:	'Bebas Neue Book', 'Helvetica', sans-serif;
$font-bebas-neue:			'Bebas Neue', 'Helvetica', sans-serif;

// Variables
$font-family-base: 			$font-roboto-condensed;
$font-family-heading: 		$font-bebas-neue;
$font-size-base-2: 			16px;
$line-height-base: 			1.31;
$color-base: 				$color-dark-2;
$color-brand: 				$color-red;

// Alerts
// Error
$alert-danger-bg: 				rgba(198, 46, 43, 0.2);
$alert-danger-border-color: 	$color-brand;
$alert-danger-color: 			$color-brand;
// Success
$alert-success-bg: 				rgba(60, 118, 61, 0.2);
$alert-success-border-color: 	rgb(60, 118, 61);
$alert-success-color: 			rgb(60, 118, 61);

$animation-speed: 		0.25s;
