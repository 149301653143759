.service {
	text-align: center;

	&__card {
		margin-bottom: 20px;

		@include susy-breakpoint($md){
			margin-bottom: 0;
		}

		&:hover {

			.service__buttons {
				background: $color-white;
			}
		}
	}

	&__icon {
		width: 120px;
		height: 105px;
		text-align: center;
	}

	&__name {

		// @include susy-breakpoint($md){
		// 	padding-left: 110px;
		// 	padding-right: 110px;
		// }

		// @include susy-breakpoint($lg){
		// 	padding-left: 40px;
		// 	padding-right: 40px;
		// }

		// @include susy-breakpoint(1225px){
		// 	padding-left: 67px;
		// 	padding-right: 67px;
		// }
	}

	&__desc,
	&__buttons {
		// padding-left: 15px;
		// padding-right: 15px;
		line-height: 1.18;
	}

	&__buttons {
		width: 100%;
		left: 0;
		font-weight: normal;
		top: 100%;
	}
}
