//developers responsive markers
.dev {
	position: relative;

	&:before {
		content: "<	460px";
		color: white;
		text-shadow: 0 0 5px black;
		position: fixed;
		top: 2%;
		left: 5%;
		font-size: 30px;
		font-weight: 700;
		z-index: 1000;
	}
	@include susy-breakpoint($xs) {
		&:before {
			content: "xs	480px";
		}
	}
	@include susy-breakpoint($sm) {
		&:before {
			content: "sm	576px";
		}
	}
	@include susy-breakpoint($md) {
		&:before {
			content: "md	768px";
		}
	}
	@include susy-breakpoint($lg) {
		&:before {
			content: "lg	992px";
		}
	}
	@include susy-breakpoint($xl) {
		&:before {
			content: "xl	1140px";
		}
	}
}
