.phone {
	@include fontsize(40px, 1.5, 1.333);
	font-family: $font-family-heading;
	font-weight: bold;

	&--lg {
		@include fontsize(45px, 1.5, 1.333);
	}

	&--inverse {
		color: $color-white;
	}
}
