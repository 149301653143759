.slide-content {
    font-size: 30px;
    line-height: 30px;
    padding: 0 !important;
    font-weight: lighter;

    h2 {
        text-transform: uppercase;
        font-size: 33px;
        font-weight: normal;
        line-height: 33px;
        margin-top: 0;
        margin-bottom: 30px;
    }

    &_characteristic {
        color: #c62e2b;
        display: flex;
        flex-direction: column;

        &_block {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;

            @include susy-breakpoint($md){
                width: 50%;
                margin-bottom: 0px;
            }
            
            span {
                font-size: 60px;
                font-weight: bold;
                line-height: 55px;
                font-family: $font-bebas-neue;
            }
        }

        @include susy-breakpoint($md){
            flex-direction: row;
        }
    }
}