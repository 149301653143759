 $i: 5;
 @while $i < 155 {
 	.u-pt-#{$i} {
 		padding-top: #{$i / 2}px;

 		@include susy-breakpoint($md){
 			padding-top: #{$i}px;
 		}
 	}
 	$i: $i + 5;
 }

 $k: 5;
 @while $k < 155 {
 	.u-pb-#{$k} {
 		padding-bottom: #{$k / 2}px;

 		@include susy-breakpoint($md){
 			padding-bottom: #{$k}px;
 		}
 	}
 	$k: $k + 5;
 }
