.cover {
	display: none;

	&--active {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:  $color-black-a-2;
		z-index: 100;
	}
}
