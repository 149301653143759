.input {

	&--text {
		background: none;
		border: none;
		padding: 10px 18px;
		margin: 0 0 12px 0;
		width: 100%;
		color: $color-gray-3;
		@include fontsize(29px, 1.5, 1.333);
		border: 2px solid $color-gray-3;
		border-radius: 5px;
	}

	&.half {

		@include susy-breakpoint($lg){
			width: calc(50% - 7px);
			float: left;

			&--last {
				float: right;
			}
		}
	}

	&--error {
		border-color: $color-brand;
		color: $color-brand;
	}

	&--check {
		display: none;

		&:checked + .label__check-view {
			&::before{
				@include pseudo();
				width: 10px;
				height: 8px;
				border-right: 2px solid $color-base;
				border-top: 2px solid $color-base;
				border-left: 0px solid transparent;
				border-bottom: 0px solid transparent;
				border-radius: 0;
				top: 3px;
				left: 4px;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-ms-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
			}
		}
	}
}

.label__check-view {
	display: inline-block;
	position: relative;
	width: 22px;
	height: 22px;
	border: 2px solid $color-base;
	border-radius: 7px;
	margin-right: 10px;
	margin-left: -32px;
	top: 4px;

	@include susy-breakpoint($md){
		top: 2px;
	}

	@include susy-breakpoint($lg){
		top: 0;
	}
}
