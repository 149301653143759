.grid {
	display: flex;
	justify-content: space-betveen;
	flex-wrap: wrap;

	&__col-5 {
		flex: 0 0 100%;
		max-width: 100%;

		@include susy-breakpoint($xs){
			flex: 0 0 50%;
			max-width: 50%;
		}

		@include susy-breakpoint($md){
			flex: 0 0 33.333%;
			max-width: 33.333%;
		}

		@include susy-breakpoint($lg){
			flex: 0 0 25%;
			max-width: 25%;
		}

		@include susy-breakpoint($xl){
			flex: 0 0 20%;
			max-width: 20%;
		}

		&--bordered {
			border-bottom: 1px solid $color-gray;
			padding: 15px 20px 18px;

			@include susy-breakpoint($xs){
				border-right: 1px solid $color-gray;
			}

			@include susy-breakpoint($sm){
				border-left: 1px solid $color-gray;
				border-right: none;

				&:nth-child(2n){
					border-right: 1px solid  $color-gray;
				}

				&:last-child{
					border-right: 1px solid  $color-gray;
				}
			}

			@include susy-breakpoint($md){
				border-right: none;

				&:nth-child(2n){
					border-right: none;
				}

				&:nth-child(3n){
					border-right: 1px solid  $color-gray;
				}

				&:last-child {
					border-right: 1px solid  $color-gray;
				}
			}

			@include susy-breakpoint($lg){
				border-right: none;

				&:nth-child(3n){
					border-right: none;
				}

				&:nth-child(4n){
					border-right: 1px solid  $color-gray;
				}

				&:last-child {
					border-right: 1px solid  $color-gray;
				}
			}

			@include susy-breakpoint($xl){
				border-right: none;

				&:nth-child(3n){
					border-right: none;
				}

				&:nth-child(4n){
					border-right: none;
				}

				&:nth-child(5n){
					border-right: 1px solid  $color-gray;
				}

				&:last-child {
					border-right: 1px solid  $color-gray;
				}
			}
		}
	}
}
